.header {
    width: 100%;
    height: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.mainheader {
    width: 100%;
    height: 6rem;
    justify-content: center;
    align-items: center;
}


.navbar {
    width: 100%;
    margin: auto;
    padding: .5rem 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
}

.logo {
    width: 250px;
}

.logo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
@media screen and (max-width: 600px) {
    .logo {
        width: 100px;
    }
    
}

.menu-options {
    display: flex;
    align-items: center;
}

.menu-list {
    gap: 2em;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.menu-list li {
    list-style: none;
}

.menu-list li a {
    color: white;
    font-weight: 600;
    font-size: 1.1rem;
    text-decoration: none;
}

.hamberger-menu {
    cursor: pointer;
    display: flex;
    align-items: center;
}

#hamberger-checkbox {
    opacity: 0;
    appearance: none;
}


.hamberger-btn,
.close-btn {
    font-size: 2rem;
    display: none;
}

@keyframes moveUpDown {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px); 
    }
    100% {
      transform: translateY(0);
    }
  }
  
  .left-image {
    animation: moveUpDown 2s ease-in-out infinite; 
  }
/* Navbar.css */

/* Define the animation */
@keyframes moveLeftRight {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-10px); /* Adjust the distance as needed */
  }
  100% {
    transform: translateX(0);
  }
}
@media (max-width: 767px) {
    .rainbow-connect-button .button-text {
      font-size: 12px; /* Adjust the font size as needed */
    }
  }
  
/* Apply the animation to the left image */
.mid-image {
  animation: moveLeftRight 2s ease-in-out infinite; /* Adjust the duration as needed */
}
  
.content-container{
    margin-top: -10rem;
    max-width: 1700px;
}
/* Navbar.css */
@media (max-width: 767px) {
    .rainbow-connect-button {
      font-size: 12px; /* Adjust the font size as needed */
    }
  }
  

@media only screen and (max-width:900px) {
.mid-image{
    width: 10rem;
}

.content-container{
    margin-top: 2rem;
}
.left-image{
   width: 3rem;
}
.right-image{
    width: 2rem;
}

    .menu-list {
        display: none;
    }

    .hamberger-btn {
        display: block;
    }

    .menu-options:has(#hamberger-checkbox:checked) .close-btn {
        display: block;
    }

    .menu-options:has(#hamberger-checkbox:checked) .hamberger-btn {
        display: none;
    }

    .menu-options:has(#hamberger-checkbox:checked) .menu-list {
        top: 100%;
        right: 0;
        gap: 1rem;
        z-index: 99;
        padding: 1rem;
        display: flex;
        align-items: flex-start;
        position: absolute;
        border-radius: 10px;
        flex-direction: column;
        border: 1px solid white;
        background: rgba(24, 23, 23, 0.85);
    }
}

@media only screen and (max-width:768px) {
    .navbar {
        width: 100%;
    }
}