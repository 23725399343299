.content-container {
  width: 85%;
  margin: auto;
  padding-bottom: 4rem;
}

.btn_primary {
  background: linear-gradient(90deg, #FF2887 1.72%, #FF2BD0 33.5%, #C76BFF 64.91%);
  color: white;
  border-radius: 12px;
  padding: 4px 20px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  outline: none;
  border: none;
  cursor: pointer;
}

.claimtoken {
  padding: 10px 40px;
  margin: 10px 0;
  background-color: transparent;
  color: white;
  border-radius: 12px;
  padding: 4px 20px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  outline: none;
  border: 2px solid #1e50ff;
  cursor: pointer;


}

.claimtoken:hover {
  background: #1e50ff;
  transition: all 0.8ms ease-in-out;
  cursor: pointer;

}

.activebutton {
  background-color: #1e4fff84;
}

.btn_primary:hover {
  background: linear-gradient(90deg, #ff2889a0 1.72%, #ff2bd170 33.5%, #c66bff83 64.91%);
}

.tokeninput {
  color: #fff;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 0;
  width: 95%;
  max-width: 1100px;
  height: 100%;
  margin-bottom: 40px;
}

.infoHeading {
  font-size: 2rem;
  font-weight: 700;
}

.infoPara {
  max-width: 70%;
  margin-top: 1rem;
}
.dragon-image{
   position: fixed;
    right: 10.7%;
     top: 15rem;
}
/* @media screen and (min-width: 1500px) {
.dragon-image{
  width: 45%;
  top:"-10rem";
  right:"-10rem";
}
   */
/* } */



  .box img {
      position: absolute;
      top: 50%;
      right: -50px; /* Adjust the value to move the image further right */
      transform: translateY(-50%); /* Adjust image size as needed */
      height: auto;
  }
.detail-card-container {
  gap: 1rem;
  width: 95%;
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

}
@media screen and (max-width: 1200px) {
  .detail-card-container {
    justify-content: center;
    margin-left: -0.5rem;
 
  }
  .container{
    justify-content: center;
    margin-left: -0.5rem;
    display: flex;
  }
  .referrals-details{
    justify-content: center;
    margin-right: 1rem;
    margin-left: -0.5rem;
  }
}
@media screen  and (min-width:"1500px") {
  .dragon-image{
    margin-left: -200px;
  }

}
.container {
  gap: 1rem;
  width: 95.9%;
  z-index: 40;
  height: 100%;
  margin-top: 2rem;
  padding-bottom: 4rem;
  justify-items: center;
  align-content: center;
  backdrop-filter: blur(2.5px);
display:flex;
justify-content: space-between;
}

.card {
  max-width: 550px;
  padding: 4rem 2rem;
  border-radius: 22px;
  border: 1px solid white;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.0792) 0%, rgba(255, 255, 255, 0.0396) 100%);
}
@media only screen and (max-width:1200px)  {
  .dragon-image{
      display: none;
  }
}
.left.card, .right.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  backdrop-filter: blur(2.5px);
  background: linear-gradient(180deg, rgba(68, 2, 2, 0.815) 0%, rgba(71, 3, 3, 0.712) 100%);

}


@media only screen and (max-width: 768px) {
  .left-image,
  .right-image {
    width: 50px; /* Adjust the width as needed */
    height: auto; /* Maintain aspect ratio */
  }
}

@media only screen and (max-width: 600px) {
  .left-image,
  .right-image {
    width: 30px; /* Adjust the width as needed */
    height: auto; /* Maintain aspect ratio */
  }
}
@media only screen and (max-width: 400px) {
  .left-image,
  .right-image {
    width: 20px; /* Adjust the width as needed */
    height: auto; /* Maintain aspect ratio */
  }
}
.token-value {
  gap: .5rem;
  display: flex;
  align-items: center;
}

.token-value span {
  font-size: 1.1rem;
}

.day-bar {
  padding: 3px 0;
  text-align: center;
  border-radius: 5px;
  font-size: .9rem;
  margin: 1.5rem 0;
}

.bal-info {
  display: flex;
  justify-content: space-between;
}

.bal-left {
  gap: .7rem;
  display: flex;
  flex-direction: column;
}


.hr {
  width: 100%;
  height: 1px;
  margin: 2rem 0;
  background: white;
}

.input-box {
  width: 100%;
  display: flex;
  overflow: hidden;
  border-radius: .5rem;
  border: 1px solid #FF6928;
}

.tokeninput {
  width: 100%;
  border: transparent;
  padding: .5rem;
  background: transparent;
  outline: transparent;
}

.input-box .btn {
  width: max-content;
  padding: .5rem;
}


.btn-group {
  /* gap: 1rem; */
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
  justify-content: space-between;
}


.btn {
  width: 100%;
  border: none;
  color: white;
  cursor: pointer;
  font-weight: 600;
  letter-spacing: 1px;
  padding: .5rem 3rem;
  border-radius: .25rem;
}

.btn:active {
  scale: 0.95;
}

.right.card .hr {
  margin: 1rem 0;
}

.details.r-btn {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.details.r-btn .btn {
  width: 150px;
  padding: .5rem 1rem;
  white-space: nowrap;
}


.referral-heading {
  gap: .5rem;
  display: flex;
}

.referral-link {
  cursor: pointer;
  display: flex;
  padding: 2px 8px;
  margin-top: 10px;
  border-radius: 5px;
  align-items: center;
  justify-content: space-between;
  background: rgba(217, 217, 217, 0.20);
}



.referral-link span {
  color: orange;
  display: flex;
  align-items: center;
}

.copy-svg {
  width: 18px;
}

.referrals-details {
  /* overflow: hidden; */
  border-radius: 22px;
  border: 1px solid white;
  width: 95.9%;
}

.referral-row {
  padding: 1.5rem;
  display: grid;
  grid-template-columns: .5fr 1fr .5fr;
}

.referrals-details>div:nth-child(2) {
  border-top: 1px solid white;
  border-bottom: 1px solid white;
}


.referral-row p:nth-child(2) {
  text-align: center;

}

.referral-row p:last-child {
  text-align: right;
  font-weight: 600;
}



@media only screen and (max-width:950px) {
  .container {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }
}

@media only screen and (max-width:768px) {
  .content-container {
    width: 90%;
  }

  .infoPara {
    max-width: 100%;
  }

  .referrals-details {
    font-size: .9rem;
  }

}

@media only screen and (max-width:426px) {

  .infoPara {
    font-size: .8rem;
  }

  .card {
    padding: 2rem 1rem;
  }

  .bal-info p {
    font-size: .8rem;
  }

  .referral-link {
    font-size: .7rem;
  }

  .right.card h2 {
    font-size: 1.1rem;
  }

  .btn {
    font-size: .7rem !important;
    padding: .5rem !important;
  }

  .details p {
    font-size: .8rem;
  }

  .details.r-btn .btn {
    width: 100px;
  }

  .referral-row {
    padding: .75rem;
  }


  .referrals-details {
    font-size: .7rem;
  }

}