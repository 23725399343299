@import url(https://fonts.googleapis.com/css?family=Poppins:100,100italic,200,200italic,300,300italic,regular,italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic);

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Poppins:wght@600&display=swap');

body {
  position: relative;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}


:root {
  --p-orange-clr: #FF6928;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans" !important;
}

hr {
  border: 1px solid #fff;
  width: 100%;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

input {
  -moz-appearance: textfield;
}

.main {
  width: 100%;
  background: url('./assets/images/bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.main-wrapper {
  width: 100%;
  height: 100%;
  background: #0808088c;
}

.orange-text {
  background: linear-gradient(90deg, #FF6928 1.72%, #FF842B 34.53%, #FFA06B 67.34%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.orange-bg {
  background: linear-gradient(90deg, #FF6928 1.72%, #FF842B 34.53%, #FFA06B 67.34%);
}

.bold {
  font-weight: 600;
}