.overlay-loader {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1000;
}

.overlayLoading {
    background-color: transparent;
    text-align: center;
}

.spinner {
    border: 5px solid rgba(200, 200, 200, 0.3);
    border-top: 5px solid var(--p-orange-clr);
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}