.detail-card {
    flex: 1;
    white-space: nowrap;
    text-align: center;
    padding: .6rem 2rem;
    border-radius: 10px;
    border: 1px solid #FFF;
    z-index: 40;
    backdrop-filter: blur(2.5px);
    background: linear-gradient(180deg, rgba(68, 2, 2, 0.705) 0%, rgba(71, 3, 3, 0.555) 100%);
}